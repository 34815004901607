// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-blog-post-js": () => import("../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-photo-permalink-js": () => import("../src/templates/photo-permalink.js" /* webpackChunkName: "component---src-templates-photo-permalink-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-books-js": () => import("../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-dispatch-js": () => import("../src/pages/dispatch.js" /* webpackChunkName: "component---src-pages-dispatch-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-misc-js": () => import("../src/pages/misc.js" /* webpackChunkName: "component---src-pages-misc-js" */),
  "component---src-pages-objects-js": () => import("../src/pages/objects.js" /* webpackChunkName: "component---src-pages-objects-js" */),
  "component---src-pages-photography-js": () => import("../src/pages/photography.js" /* webpackChunkName: "component---src-pages-photography-js" */),
  "component---src-pages-work-js": () => import("../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

